.skills-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 6.7rem;
}

.skills-container h1 {
  font-size: var(--fs-titleh1);
  color: white;
  padding-bottom: 2rem;
  text-align: center;
}

.skills-container h2 {
  font-family: var(--textFont);
  font-size: var(--fs-titleh2);
  color: white;
  padding-bottom: 3.5rem;
}

.skills-container .section-description {
  font-family: var(--textFont);
  padding-bottom: 6rem;
  color: white;
  font-size: var(--fs-other);
  text-align: center;
  font-weight: 200;
  width: 100%;
  max-width: var(--sz-screen-width);
}

.skills-container .title {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;
}

.skill-list img {
  margin-right: 1.2rem;
}

.skill-list {
  width: var(--sz-screen-width);
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
}

.skill-stack {
  max-width: 34rem;
  width: 100%;
}

@media screen and (max-width: 1400px) {
  .skill-stack {
    max-width: 32rem;
  }
}

@media screen and (max-width: 450px) {
  .skill-list {
    flex-direction: column;
    align-items: center;
  }

  .skills-container {
    padding: 2.7rem 2.25rem 0px 2.25rem;
  }

  .skill-stack {
    margin-bottom: 4.5rem;
  }

  .skills-container h2 {
    margin-bottom: 3rem;
    padding-bottom: 0px;
  }
}
