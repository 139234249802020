.about-me {
  --btn-width: clamp(13rem, 14vw, 17.8rem);
  --btn-height: clamp(3rem, 5vw, 5rem);
  --icon-width: clamp(1.5rem, 2.3vw, 3rem);
}

.about-me {
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.about-me .left-side {
  width: 30%;
  height: 100%;
  background: url("../../Assets/me_working.jpg");
  background-size: cover;
}

.about-me .color_layer {
  height: 100%;
  width: 100%;
  /* background: rgb(0, 74, 255);
  background: linear-gradient(
    90deg,
    rgba(0, 74, 255, 0.4) 0%,
    rgba(168, 153, 109, 0.5) 20%,
    rgba(255, 194, 34, 0.5) 50%,
    rgba(168, 172, 109, 0.5) 80%,
    rgba(0, 129, 255, 0.4) 100%
  ); */
  /* background: rgb(255, 194, 34);
  background: linear-gradient(
    180deg,
    rgba(255, 194, 34, 0.5) 0%,
    rgba(255, 194, 34, 0.5) 50%,
    rgba(0, 74, 255, 0.5) 100%
  ); */
  background: rgb(255, 194, 34);
  background: linear-gradient(
    00deg,
    rgba(255, 194, 34, 0.35) 0%,
    rgba(255, 194, 34, 0.35) 50%,
    rgba(0, 74, 255, 0) 100%
  );

  /* background: rgb(255, 194, 34, 0.3); */
}

.about-me .right-side {
  width: 70%;
  height: 100%;
  /* margin-top: var(--mg-top-page); */
  padding: 5% 6% 0 6%;
  display: grid;
  grid-template-rows: 90% 10%;
  grid-template-areas:
    "content"
    "link";
}

.about-me .paragraph {
  font-size: var(--fs-baseText);
  font-weight: 400;
  font-family: var(--textFont);
  color: white;
  margin-top: 20px;
  margin-bottom: 20px;
  word-wrap: break-word;
}

.about-me .overtitle {
  font-size: var(--fs-other);
  font-weight: lighter;
  color: white;
}

.about-me .content {
  grid-area: content;
}

.about-me h1 {
  font-size: var(--fs-titleh1);
  color: white;
  margin-bottom: 20px;
}

.about-me h2 {
  font-size: var(--fs-other);
  color: var(--yellowCollor);
  font-weight: bold;
}

a,
.contact-links,
.contact-links span {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contact-links span {
  margin-right: 18px;
}

.contact-links span:hover {
  cursor: pointer;
}

.contact-links span img {
  margin-right: 10px;
  width: var(--icon-width);
}

.whats-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--btn-width);
  height: var(--btn-height);
  border-radius: 50px;
  background-color: #03b13e;
}

@media screen and (max-height: 768px) {
  .about-me {
    --btn-width: clamp(13rem, 14vw, 13rem);
    --btn-height: clamp(3rem, 5vw, 3.5rem);
    --icon-width: clamp(1.5rem, 2.3vw, 2rem);
  }
}

@media screen and (max-width: 450px) {
  .about-me .left-side {
    display: none;
  }

  .about-me .right-side {
    width: 100%;
    padding-top: 10%;
  }
  @media screen and (min-height: 700px) {
    .about-me .right-side {
      padding-top: 23%;
    }
  }
}
