nav {
  --fs-header-subtitle: clamp(var(--s16), 3vw, var(--s36));
  --fs-nav-text: min(2rem, 3vw);
  --sz-flag: min(4rem, 6vw);
}

nav {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  z-index: 2;
  padding-left: 5%;
  padding-right: 5%;
  gap: 20px;
  /* background-color: blue; */
}

nav img {
  width: var(--sz-flag);
  height: auto;
}

.deselected {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.deselected p {
  color: rgb(172, 172, 172) !important;
}

.selected {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.lang {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 67px;
}

.lang,
.lang p {
  transition: all 0.3s ease-in;
}

.lang p {
  font-family: var(--textFont);
  font-size: var(--fs-nav-text);
  font-weight: 300;
  color: white;
  margin-left: 8px;
}

.lang:hover {
  cursor: pointer;
}

.flag {
}

@media screen and (max-width: 450px) {
  nav {
    justify-content: center;
  }

  .lang {
    margin-top: 30px;
  }
}

@media screen and (max-height: 768px) {
  nav {
    --fs-nav-text: min(1.5rem, 3vw);
    --sz-flag: min(3.5rem, 6vw);
  }
}
