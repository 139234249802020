.video_modal .close_btn {
  position: absolute;
  right: 3%;
  top: 5%;
  z-index: 1;
}

.video_modal video {
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 1366px) {
  .video_modal video {
    width: 720px;
    height: auto;
  }
}
