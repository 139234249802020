footer {
  width: 100%;
  height: auto;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 3rem;
}

footer img {
  width: 3rem;
  height: auto;
}

footer p {
  font-family: var(--textFont);
  font-weight: 200;
  font-size: 1.6rem;
  color: white;
  margin-bottom: 1.3rem;
  text-align: center;
}

.footer-links {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 2.3rem;
}

.footer-links img:hover {
  cursor: pointer;
}

.footer-links img:nth-of-type(3) {
  width: 3.7rem;
  height: auto;
}

.footer-name {
  color: var(--yellowCollor);
}
