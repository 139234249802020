.projects-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 6.7rem 2rem 0px 2rem;

  --mg-bottom-h1: clamp(8rem, 75rem, 19vw, 17.5rem);
  --mg-bottom-p: clamp(3.6rem, 3.5vw, 7.2rem);
  --description-sz: clamp(1rem, 1.4vw, 1.6rem);
  --project-title-sz: 2rem;
  --stack-sz: clamp(1rem, 1.4vw, 1.2rem);
}

.projects-wrapper h1 {
  margin-top: var(--mg-top-h1);
  font-size: var(--fs-titleh1);
  font-family: var(--HeadlineFont);
  margin-bottom: 2rem;
  color: white;
}

.projects-wrapper .paragraph {
  font-size: var(--fs-other);
  font-family: var(--textFont);
  margin-bottom: var(--mg-bottom-p);
  max-width: var(--sz-screen-width);
  font-weight: 200;
  color: white;
  text-align: center;
}

@media screen and (max-width: 450px) {
  .projects-wrapper {
    padding: 2.7rem 0px 0px 0px;
  }
}
