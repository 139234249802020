.bottom-link {
  --sz-arrow-w: clamp(1.3rem, 1.4vw, 2.6rem);
}

.bottom-link-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 3rem;
}

.bottom-link {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  grid-area: link;
}

.bottom-link a {
  text-decoration: none;
}

.bottom-link img {
  width: var(--sz-arrow-w);
  height: auto;
}

.bottom-link:hover {
  cursor: pointer;
}

.bottom-link p {
  font-family: var(--textFont);
  font-size: var(--fs-other);
  font-weight: 200;
  margin-bottom: 14px;
  color: white;
}

.bounce {
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.paused {
  animation-play-state: paused;
}

@media screen and (max-height: 768px) {
  .bottom-link {
    --sz-arrow-w: clamp(1.3rem, 1.4vw, 1.8rem);
  }
}
