.project {
  display: flex;
  max-width: 100%;
  width: var(--sz-screen-width);
  margin: 0px 3.5% 10rem 3.5%;
}

.project h2 {
  font-size: var(--project-title-sz);
  font-weight: 600;
  color: white;
  text-align: right;
  padding-top: 1.6rem;
  padding-bottom: 1.3rem;
}

.project .aditional-info {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.info-container {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.description {
  background-color: #525050;
  padding: 1.4rem 2rem;
  color: white;
}

.description p {
  font-size: var(--fs-baseText);
  font-weight: 300;
}

.buttons {
  display: flex;
  margin-left: 2.1rem;
}

.project .buttons img {
  margin-right: 1rem;
}

.project .buttons a {
  margin-right: 2rem;
  font-size: var(--description-sz);
  font-weight: 300;
  color: white;
}

.aditional-info .stack {
  font-size: var(--stack-sz);
  font-weight: 400;
  color: white;
  margin-left: 2.1rem;
  margin-top: 0.5rem;
}

.disabled,
.disabled img {
  color: #4d4d4d !important;
}

.disabled img.svg {
  fill: rgb(77, 77, 77) !important;
}

.disabled:hover {
  cursor: default !important;
}

.video-container {
  overflow: hidden;
  width: 40%;
  height: auto;
  background-color: rgb(44, 44, 44);
}

.video-right {
  border-radius: 0px 50px 0px 0px;
  order: 2;
}

.video-left {
  border-radius: 50px 0px 0px 0px;
  order: 1;
}

.info-right {
  order: 1;
}

.info-right h2 {
  text-align: left;
}

.info-left {
  order: 2;
}

.info-left .description,
.soon_description {
  border-radius: 0 10px 10px 0;
}

.info-right .description,
.soon_description {
  border-radius: 10px 0px 0px 10px;
}

.video-container video {
  width: 100%;
  height: 100%;
  object-fit: fill;
  position: initial;
}

@media screen and (max-width: 450px) {
  .project {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5rem;
  }

  .video-container,
  .info-container {
    width: 100%;
  }

  .project h2 {
    padding-right: 2rem;
    padding-left: 2rem;
    /* background-color: #525050; */
    border-radius: 0px 0px 50px 50px;
    text-align: center;
  }

  .video-container {
    border-radius: 0px 0px 0px 0px;
  }

  .video-right {
    order: 1;
  }
  .left-right {
    order: 2;
  }

  .info-left .description,
  .info-left .aditional-info,
  .info-left .soon_description {
    background-color: none;
    margin-right: 2rem;
    margin-left: 0px;
  }

  .info-right .description,
  .info-right .aditional-info,
  .info-right .soon_description {
    background-color: none;
    margin-right: 0px;
    margin-left: 2rem;
  }

  .info-container .aditional-info .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
