.separator {
  width: 90%;
  height: 0.5rem;
  border-radius: 5rem;
  background-color: #ffc222;
  margin-top: 0.5rem;
  margin-bottom: 1.1rem;
}

.item h3 {
  font-family: var(--textFont);
  font-weight: 700;
  font-size: var(--fs-baseText);
  color: #ffc222;
}

.item p {
  font-family: var(--textFont);
  font-weight: 200;
  font-size: var(--fs-baseText);
  color: white;
}

.html5,
.css3,
.javascript,
.postgres,
.git,
.github,
.photoshop,
.english_2,
.express {
  width: 2.8rem;
  height: auto;
}

.react {
  width: 3.1rem;
  height: auto;
}

.node {
  width: 4.3rem;
  height: auto;
}

.figma {
  width: 2rem;
}

.htmlcss_item {
  margin-bottom: 5.5rem;
}

.javascript_item {
  margin-bottom: 2.4rem;
}

.gitgithub_item,
.nodeexpress_item {
  margin-bottom: 2.7rem;
}

.figmaphotoshop_item {
  margin-bottom: 3.5rem;
}

@media screen and (max-width: 450px) {
  /* .html5,
.css3,
.javascript,
.postgres,
.git,
.github,
.photoshop,
.english_2,
.express,
.react {
  width: 2.8rem;
  height: auto;
} */
  .htmlcss_item,
  .javascript_item,
  .gitgithub_item,
  .nodeexpress_item,
  .figmaphotoshop_item {
    margin-bottom: 2.4rem;
  }
}
