.soon_stack {
  width: 50%;
  height: 1.6rem;
  background-color: #525050;
  margin-left: 2rem;
  border-radius: 1rem;
}

.soon_links {
  width: 12.2rem;
  height: 2.1rem;
  background-color: #3d3c3c;
  display: flex;
  align-items: center;
  justify-content: center;
}

.soon_links p {
  font-size: 5rem;
  color: white;
}

.circle {
  border-radius: 5rem;
  width: 2rem;
  height: 2rem;
  background-color: #525050;
}

.rectangle {
  border-radius: 5rem;
  width: 12rem;
  height: 2rem;
  background-color: #525050;
}

.soon_video {
  background-color: #3d3c3c;
  height: 27.3rem;
}

.soon_video p {
  /* font-size: 28.8rem; */
}

.soon_description {
  margin: 5.6rem 0px 0.5rem 0px;
  max-height: 7.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #525050;
  padding: 1.4rem 2rem;
  color: white;
}

.soon_description p {
  font-size: var(--fs-titleh1);
  font-weight: 700;
  font-family: var(--HeadlineFont);
  color: #918f8f;
}

.dots-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.dots {
  background-color: #918f8f;
  border-radius: 5rem;
  max-width: 100%;
  width: 1rem;
  height: 1rem;
}

.dots_xl {
  max-width: 100%;
  width: 5rem;
  height: 5rem;
  margin: 0px 2.5rem 0px 2.5rem;
}

.dots_sm {
  max-width: 100%;
  width: 0.9rem;
  height: 0.9rem;
  margin: 0px 1rem 0px 1rem;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.buttons {
  display: flex;
  gap: 2rem;
}

@media screen and (max-width: 450px) {
  .soon_video {
    height: 21rem;
  }

  .soon_info .buttons {
    justify-content: flex-start !important;
    margin-left: 0px;
  }

  .circle {
    width: 2rem;
    height: 2rem;
  }

  .rectangle {
    width: 9rem;
    height: 2rem;
  }
}
