.resume-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background-color: #525050;
  padding: 4.7rem 0px 4.7rem 0px;

  --mg-top-h1: clamp(3rem, 3.5vw, 6.7rem);
  --mg-bottom-h1: clamp(2.3rem, 2.4vw, 4.6rem);
  --mg-bottom-p: clamp(1.4rem, 1.5vw, 2.8rem);
  --mg-bottom-btn: clamp(1.4rem, 1.6vw, 2.9rem);
}

.resume-container h1 {
  font-size: var(--fs-titleh1);
  margin-bottom: 2rem;
  font-family: var(--HeadlineFont);
  color: white;
}

.resume-container .paragraph {
  font-size: var(--fs-other);
  margin-bottom: 4rem;
  font-family: var(--textFont);
  font-weight: 200;
  color: white;
  max-width: var(--sz-screen-width);
}

.resume-container button {
  border-radius: 5rem;
  border: none;
  padding: 1.2rem 2.5rem;
  background-color: #ffc222;
  font-family: var(--textFont);
  font-size: var(--fs-baseText);
  font-weight: 700;
}

.resume-container button:hover {
  cursor: pointer;
  transition: all 0.2s ease;
  transform: scale(1.25);
}

@media screen and (max-width: 450px) {
  .resume-container {
    padding: 2.7rem 2.25rem 2.7rem 2.25rem;
  }
}
