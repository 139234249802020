@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");

:root {
  --textFont: "Nunito", sans-serif;
  --HeadlineFont: "Source Sans Pro", sans-serif;
  --fs-baseText: clamp(1rem, 1.4vw, 1.8rem);
  --fs-titleh1: clamp(2.4rem, 4vw, 4.8rem);
  --fs-other: clamp(1.2rem, 1.8vw, 2.4rem);
  --fs-titleh2: clamp(1.6rem, 1.8vw, 3.2rem);
  --mg-top-page: 17.5rem;
  --s12: 1.2rem;
  --s16: 1.6rem;
  --s20: 2rem;
  --s24: 2.4rem;
  --s36: 3.6rem;
  --s48: 4.8rem;
  --sz-screen-width: 120rem;
  --yellowCollor: #daa520;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* div {
  background-color: rgba(red, green, blue, 0.5);
  outline: 1px solid red;
} */

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

.wrapper {
  width: 100%;
  height: 100%;
}

.app {
  display: flex;
  flex-direction: column;
  position: relative;
}

.yellow {
  color: var(--yellowCollor);
}

header,
section {
  background-color: #1d1d1d;
}

::-webkit-scrollbar {
  width: 1.5rem;
}

::-webkit-scrollbar-track {
  background: #444242;
}

::-webkit-scrollbar-thumb {
  background: var(--yellowCollor);
  border-radius: 5rem;
  border: 0.4rem solid #444242;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(247, 207, 106);
  cursor: pointer;
}

@media screen and (max-height: 768px) {
  :root {
    --fs-baseText: clamp(1rem, 1.4vw, 1.4rem);
    --fs-titleh1: clamp(2.4rem, 4vw, 3.2rem);
    --fs-other: clamp(1.2rem, 1.8vw, 1.6rem);
  }
}

@media screen and (max-width: 1366px) {
  :root {
    --sz-screen-width: 110rem;
  }
}

@media screen and (max-width: 450px) {
  @media screen and (min-height: 700px) {
    :root {
      --fs-baseText: clamp(1rem, 1.5vh, 3rem);
      --fs-titleh1: clamp(2.4rem, 4vw, 3.2rem);
      --fs-other: clamp(1.4rem, 1.8vw, 1.7rem);
    }
  }
  :root {
    --sz-screen-width: 100%;
  }
}
