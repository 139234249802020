.modal-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(29, 29, 29, 0.5);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  color: #1d1d1d;
  z-index: 1;
  padding: 5%;

  --mg-bottom-h1: clamp(1.4rem, 1.5vw, 2.8rem);
}

.close_btn:hover {
  cursor: pointer;
}

.modal-wrapper .close_btn:hover {
  cursor: pointer;
}

.modal-container {
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  border: 1px solid rgb(187, 185, 185, 0.3);
}

@media screen and (max-width: 450px) {
  .modal-wrapper .close_btn {
    right: 6%;
  }
}
