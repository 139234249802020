header {
  --fs-header-subtitle: clamp(var(--s16), 3vw, var(--s36));
  --fs-header-title: clamp(var(--s24), 4vw, var(--s48));
  --fs-header-icon: clamp(0.8rem, 2vw, var(--s20));
  --sz-header-icon-h: clamp(1.769rem, 5vw, 5rem);
  --sz-header-icon-w: clamp(2.2rem, 5vw, 5.5rem);
  --sz-header-image: clamp(13.2rem, 15vw, 26.4rem);
  --mg-header-icon: clamp(1rem, 2vw, 3.3rem);
}

/* .wrapper header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
} */

header {
  height: 100vh;

  display: grid;
  grid-template-rows: 10% 75% 15%;
  grid-template-areas:
    "none"
    "header-content"
    "link";
  padding-left: 5%;
  padding-right: 5%;
}

.hero-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-area: header-content;
}

header h1 {
  font-family: var(--HeadlineFont);
  font-size: var(--fs-header-title);
  color: white;
  margin-top: 12px;
}

header p {
  font-family: var(--textFont);
  font-size: var(--fs-header-subtitle);
  color: white;
  text-align: center;
}

.stack-icons {
  display: flex;
  flex-direction: row;
  margin-top: 35px;
}

.icon-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-box {
  margin-right: var(--mg-header-icon);
}

.icon-box:last-child {
  margin-right: 0;
  margin-left: 0;
}

.icon-box p {
  font-family: var(--textFont);
  font-size: var(--fs-header-icon);
  font-weight: 200;
}

.img {
  display: flex;
  justify-content: center;
  height: 55px;
}

.hero-img {
  width: var(--sz-header-image);
  height: auto;
}

.sub-text {
  margin-top: var(--s12);
}

.stack-icon {
  width: var(--sz-header-icon-w);
  height: var(--sz-header-icon-h);
}

@media screen and (min-height: 969px) {
  .hero-container {
    justify-content: normal;
  }
}

@media screen and (max-height: 768px) {
  /* .hero-container {
    background-color: blue;
  } */
  header {
    --fs-header-subtitle: clamp(var(--s16), 3vw, 2rem);
    --fs-header-title: clamp(var(--s24), 4vw, 3.2rem);
    --fs-header-icon: clamp(0.8rem, 2vw, 1.2rem);
    --sz-header-icon-h: clamp(1.769rem, 5vw, 3rem);
    --sz-header-icon-w: clamp(2.2rem, 5vw, 3.5rem);
    --sz-header-image: clamp(13.2rem, 15vw, 18.4rem);
    --mg-header-icon: clamp(1rem, 2vw, 3.3rem);

    grid-template-rows: 5% 80% 15%;
  }
}

@media screen and (max-width: 450px) {
  header {
    --mg-header-icon-node-react: clamp(1.5rem, 2vw, 3.3rem);
    --mg-header-icon: clamp(0.5rem, 2vw, 2rem);

    --sz-header-icon-h: clamp(1.769rem, 5vw, 3rem);
    --sz-header-icon-w: clamp(2.2rem, 5vw, 3.5rem);

    --fs-header-title: clamp(2rem, 5.3vw, 3.2rem);
  }

  .icon-box:nth-child(4),
  .icon-box:nth-child(5) {
    margin-right: var(--mg-header-icon-node-react);
  }
}
