.language_modal {
  width: 40%;
  height: 35%;
  background-color: #3d3c3c;
}

.language_modal h1 {
  color: #bbb9b9;
  font-size: var(--fs-titleh1);
  font-family: var(--HeadlineFont);
  margin-bottom: var(--mg-bottom-h1);
}

.language_modal a {
  font-size: var(--fs-other);
  font-weight: 700;
  font-family: var(--textFont);
  color: white;
  padding: 1% 3%;
  background-color: #242424;
  border-radius: 5rem;
}

.language {
  width: 30%;
  display: flex;
  justify-content: center;
}

.language:hover {
  cursor: pointer;
  background-color: #ffc222;
  color: #1d1d1d;
}

.language_modal .close_btn {
  position: absolute;
  right: 3%;
  top: 10%;
}

.btn-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 5%;
}

@media screen and (max-width: 450px) {
  .language_modal {
    width: 70%;
  }
  .btn-row {
    flex-direction: column;
  }
  .language {
    width: 60%;
  }
  .language:first-child {
    margin-bottom: 5%;
  }
  .language_modal a {
    padding: 2% 4%;
  }
}
